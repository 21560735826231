<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Resolved Swipes</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="3" sm="3" lg="3" xl="3">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12"  md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="AuditLogsData"
          :items="AuditLogsItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="itemsPerPage"
          :loading="isLoading"
          hide-default-footer
          loading-text="Loading... Please wait"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <!-- :options.sync="pagination" @update:options="getVerifyPayPagination()" 
          hide-default-footer
           -->
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Audits recorded yet
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.swipe_time_stamp`]="{ item }">
            <span>{{
              item.swipe_time_stamp != null
                ? get_date(item.swipe_time_stamp)
                : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.geo`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  @click="
                    item.user_lat != undefined ||
                    item.user_long != undefined ||
                    item.user_lat != null ||
                    item.user_long != null
                      ? view_user_location(item)
                      : ''
                  "
                >
                  <v-icon
                    v-on="on"
                    small
                    :color="
                      item.user_lat != undefined ||
                      item.user_long != undefined ||
                      item.user_lat != null ||
                      item.user_long != null
                        ? 'primary'
                        : ''
                    "
                    v-text="
                      item.user_lat != undefined ||
                      item.user_long != undefined ||
                      item.user_lat != null ||
                      item.user_long != null
                        ? 'mdi-map-marker'
                        : 'mdi-map-marker-off'
                    "
                  ></v-icon>
                </a>
              </template>
              <span
                class="white--text"
                v-if="
                  item.user_lat != undefined ||
                  item.user_long != undefined ||
                  item.user_lat != null ||
                  item.user_long != null
                "
                >View Geo</span
              >
              <span class="white--text" v-else> Geo not available</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.qr_location_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.qr_location_name != null &&
                    item.qr_location_name != undefined
                      ? item.qr_location_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.qr_location_name != null &&
                  item.qr_location_name != undefined
                    ? item.qr_location_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.organisation_name != null &&
                    item.organisation_name != undefined
                      ? item.organisation_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.organisation_name != null &&
                  item.organisation_name != undefined
                    ? item.organisation_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.user_name != null && item.user_name != undefined
                      ? item.user_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.user_name != null && item.user_name != undefined
                    ? item.user_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-on="on" v-if="item.source == 'WHATSAPP'">
                  <v-icon color="green" small>mdi-whatsapp</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'CAMERA'">
                  <v-icon color="primary" small>mdi-camera</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                  <v-icon color="red" small>mdi-redo-variant</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'DATACORRECTION'">
                  <v-icon color="purple" small>mdi-human-edit</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'PROXY'">
                  <v-icon color="blue" small>mdi-odnoklassniki</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                        <v-icon color="black" small
                          > mdi-desktop-classic</v-icon
                        >
                      </div>
                <div v-on="on" v-else-if="item.source == 'FACE'">
                  <v-icon color="primary" small>mdi-account-box</v-icon>
                </div>
                <div v-on="on" v-else-if="item.source == 'BEACON'">
                  <v-icon color="blue" small>mdi-bluetooth</v-icon>
                </div>
              </template>
              <span v-if="item.source == 'WHATSAPP'" class="white--text"
                >Source: Whatsapp</span
              >
              <span v-if="item.source == 'FACE'" class="white--text"
                >Source: Face</span
              >
              <span v-if="item.source == 'BEACON'" class="white--text"
                >Source: Beacon</span
              >
              <span v-else-if="item.source == 'CAMERA'" class="white--text"
                >Source: QR</span
              >
              <span class="white--text" v-else-if="item.source == 'RESWIPE'"
                >Source: Re-Scan</span
              >
              <span
                v-else-if="item.source == 'DATACORRECTION'"
                class="white--text"
              >
                Source: Data Correction</span
              >
              <span
                      v-else-if="item.source == 'DESKTOP'"
                      class="white--text"
                      >Source : Desktop</span
                    >
              <span v-else-if="item.source == 'PROXY'" class="white--text">
                Source: PROXY</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          @input="checkPagination"
          :length="pageCount"
        ></v-pagination>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <ViewGeoSwipes
      :viewGeoSwipesDialog="viewGeoSwipesDialog"
      @close_view_geo_swipes_dialog="viewGeoSwipesDialog = false"
      :userObject="userObject"
      :swipeData="swipeData"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllOrganisationSwipes } from "@/graphql/queries.js";
import ViewGeoSwipes from "@/components/Dialogs/AuditLogs/ViewGeoSwipes.vue";

export default {
  components: {
    Overlay,
    SnackBar,
    ViewGeoSwipes,
  },
  data: () => ({
    // pagination: {
    //   itemsPerPage: 99,
    //   page: 1,
    // },
    itemsPerPage: 100,
    page: 1,
    pageCount: 0,
    swipeData: [],
    userObject: {},
    viewGeoSwipesDialog: false,
    AuditLogsData: [
    { text: "Source", value: "type" },
    { text: "Swiped Date/Time", width: "", value: "swipe_time_stamp" },
    { text: "Organization Name", width: "", value: "organisation_name" },
    
    { text: "Member Id", value: "employee_id" },
    { text: "Member Name", value: "user_name" },
     
     
      { text: "Contact Number", value: "user_full_contact_number" },
    
      { text: "Swiped at Location", value: "qr_location_name" },
      { text: "", value: "geo" },
    ],
    AuditLogsItems: [],
    search: "",
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    isLoading: false,
    next_token: null,
    Count: 0,
    storePageCount: 0,
  }),
  watch: {},
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_audit_list();
  },
  methods: {
    checkPagination() {
      if (
        this.next_token &&
        this.next_token != "" &&
        this.page > this.storePageCount
      ) {
        this.get_audit_list(this.next_token);
      }
    },
    view_user_location(item) {
      this.userObject = item;
      this.swipeData.push({
        position: {
          lat: Number(item.user_lat),
          lng: Number(item.user_long),
        },
      });
      this.viewGeoSwipesDialog = true;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.split(",")[0] + "," + a.split(",")[1].toUpperCase();
    },
    async get_audit_list(next_token) {
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllOrganisationSwipes, {
            input: {
              limit: 101,
              nextToken: next_token,
            },
          })
        );
        // console.log(result);
        this.next_token = JSON.parse(
          result.data.GetAllOrganisationSwipes
         
        ).nextToken;
        // console.log("next_token", this.next_token);
        for (
          let i = 0;
          i <
          JSON.parse(result.data.GetAllOrganisationSwipes).swipesDetails.Items
            .length;
          i++
        ) {
          this.AuditLogsItems.push(
            JSON.parse(result.data.GetAllOrganisationSwipes).swipesDetails
              .Items[i]
          );
        }
        // console.log(this.next_token);
        this.$forceUpdate();
        this.isLoading = false;
        this.overlay = false;
        this.storePageCount = this.page;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
